var DS = window.DS || {};
DS.imageStrip = function() {
  var els = {};

  var listen = function() {

    els.buttons.on({
      click: function(e) {
        animate.call(this);
      }
    });

    els.window.on({
      scroll: function() {
        animateImages();
      }
    });

  };
  var hideCopy = function() {
    els.copyBlocks.each(function() {
        var fullHeight = $(this).outerHeight() + 50;
        var unexpandedHeight = $(this).children('p').eq(0).outerHeight() + 14;

        $(this).attr('data-fullheight',fullHeight+'px');
        $(this).attr('data-miniheight',unexpandedHeight+'px');
        $(this).css('height',unexpandedHeight+'px');
    });
  };

  var animate = function() {
    var text = $(this).siblings('.copy');
    var initialButtonText = $(this).text();
    var newButtonText = $(this).next('.hide-details').text();

    if( text.find('p').length > 1 ) {
      if( text.hasClass('expanded') ) {
        text.animate({
          'height': text.attr('data-miniheight')}, 700);
          $(this).next('.hide-details').html(initialButtonText);
          $(this).html(newButtonText);
      }
      else {
        text.animate({
          'height': text.attr('data-fullheight')}, 700);
          $(this).next('.hide-details').html(initialButtonText);
          $(this).html(newButtonText);
      }    
      text.toggleClass('expanded');
    }
    
  };

  var animateImages = function() {
    var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
      
    if ($animatables.length == 0) {
      $(window).off('scroll', animateImages);
    }

    $animatables.each(function(i) {
      var $animatable = $(this);
     if (($animatable.offset().top + $animatable.height() - 20) < offset) {
       $animatable.removeClass('animatable').addClass('animated');
     }
   });
  };

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        copyBlocks: $('.expandable.copy'),
        buttons: $('.read-more'),
      };
      hideCopy();
      listen();
    }
  };
};

$(function() {
  var imageStrip = new DS.imageStrip();
  imageStrip.init();
});
