var DS = window.DS || {};
DS.footer = function() {
  var els = {};

  var listen = function() {
    els.window.on({
      resize: function(e) {
        parallaxit();
      }
    });
  };

  var parallaxit = function() {
		var contentWidth = els.window.width();
    var footerHeight = els.footer.height();

    if( contentWidth > 768 ) {
      els.siteContent.css({
  			"margin-bottom" : footerHeight + 165
  		});
    }
  }

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        siteContent: $('.site-content'),
        footer: $('.footer-component'),
      };
      parallaxit();
      listen();
    }
  };
};

$(function() {
  var footer = new DS.footer();
  footer.init();
});
