var DS = window.DS || {};
DS.header = function() {
  var els = {};
  var focusoutTimout = null;

  var listen = function() {
    els.contactLink.on({
      click: function(e) {
        if (els.headerComponent.hasClass('mobile-nav-open')) {
          els.html.toggleClass('no-scroll');
          els.headerComponent.toggleClass('mobile-nav-open');
          els.mobileNavButton.toggleClass('is-active');
        }

        $("html, body").animate({ scrollTop: $(document).height() }, "slow");
        return false;
      }
    });

    els.window.on({
      scroll: function(e) {
        if ( els.headerComponent.css("position") === "fixed" ) {
          els.headerComponent.toggleClass('scrolled', $(this).scrollTop() > els.headerComponent.height());
        }
      }
    });
    els.mobileNavButton.on({
      click: function (e) {
        els.mobileNavButton.toggleClass('is-active');
        els.headerComponent.toggleClass('mobile-nav-open');
        els.html.toggleClass('no-scroll');
      }
    });
  };

  var detectHero = function() {
    if( els.headerComponent.siblings('.hero-component').length == 0 ) {
      els.siteContent.addClass('no-hero');
    }
  };

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        mobileNavButton: $('button.hamburger'),
        siteContent: $('.site-content'),
        headerComponent: $('.header-component'),
        contactLink: $('.contact-us'),
      };
      detectHero();
      listen();
    }
  };
};

$(function() {
  var header = new DS.header();
  header.init();
});
