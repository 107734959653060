var DS = window.DS || {};
DS.heroVideos = function() {
  var els = {};
  var vars = {};

  var listen = function() {

    $('.main-slider.slick-initialized').on({
        beforeChange: function(event, slick) {
          slick = $(slick.$slider);
          if( els.slideWrapper.find('.slick-current .show-video').length !== 0 ) {
            playPauseVideo(slick,"pause");
          }
        },
        afterChange: function(event, slick) {
          slick = $(slick.$slider);
          if( els.slideWrapper.find('.slick-current .show-video').length !== 0 ) {
            playPauseVideo(slick,"play");
          }
        },
        lazyLoaded: function(event, slick, image, imageSource) {
          vars.lazyCounter++;
          if (vars.lazyCounter === lazyImages.length){
            lazyImages.addClass('show');
            // slideWrapper.slick("slickPlay");
          }
      }
    });
  };

  // POST commands to YouTube or Vimeo API
  var postMessageToPlayer = function(player, command) {
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  };

  var playPauseVideo = function(slick, control) {
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");

    slideType = currentSlide.find('.show-video').attr("class").split(" ")[2];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "vimeo") {
        switch (control) {
            case "play":
              if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
                currentSlide.addClass('started');
                postMessageToPlayer(player, {
                  "method": "setCurrentTime",
                  "value" : startTime
                });
              }
              postMessageToPlayer(player, {
                "method": "play",
                "value" : 1
              });
              break;
            case "pause":
              postMessageToPlayer(player, {
                "method": "pause",
                "value": 1
              });
              break;
          }
    } else if (slideType === "video") {
      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play"){
          video.play();
        } else {
          video.pause();
        }
      }
    }
  };

  var initSlider = function() {
    $(els.slideWrapper).slick({
      autoplay: true,
      autoplaySpeed: 12000,
      lazyLoad:"progressive",
      arrows:false,
      dots:true,
      speed: 400,
      cssEase:"easeInOutExpo",
      useTransform: false,
    });

    if( els.slideWrapper.hasClass('slick-initialized') ) {
      slick = els.slideWrapper;
        setTimeout(function(){
          playPauseVideo(slick,"play");
        }, 1000);
    }
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        window: $(window),
        html: $('html'),
        component: $el,
        slideWrapper: $('.main-slider'),
        iframes: $('.main-slider iframe'),
        lazyImages: $('.main-slider .image'),
      };
      vars = {
        lazyCounter: 0,
      };
      initSlider();
      listen();
    }
  };
};

$(function() {
  var heroVideos = new DS.heroVideos();
  heroVideos.init();
});
