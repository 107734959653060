var DS = window.DS || {};
DS.filters = function() {
  var els = {};
  var focusoutTimout = null;

  var listen = function() {

    els.selects.on({
      change: function(e) {
        addChip.call(this);
        filterProjects();
      }
    });

    $(document).on({
      mouseup: function() {
        $('.options').each(function() {
          var $this = $(this);
          if($this.is(':visible')) {
            $this.hide();
          }
        })
      }
    });

    $('.applied-filters').on('click', '.close-button', function(e) {
      e.preventDefault();
      
      var chipText = $(this).parent().text();
      var chipText = chipText.replace(/\s{2,}/g,' ').trim();

      for ( var filter in vars.filterData ) {
        if( Object.keys(vars.filterData[filter]).length > 0 ) {
          if(vars.filterData[filter].hasOwnProperty(chipText)) {
            $(this).closest('.chip').remove();
            delete vars.filterData[filter][chipText];
          }
        }
      }
     
     var selectEl = $("select[name='" + $(this).parent().attr('data-filter') + "']");
     var firstOption = selectEl.find("option:first-child").text();
     var styledSelect = selectEl.next('div.styledSelect');

     styledSelect.text(firstOption).removeClass('active');
     filterProjects();
    })

  };

  var addChip = function() {
    var chipID = $(this).children(':selected').val();
    var chipText = $(this).children(':selected').text();
    var selectEl = $(this).attr('name');

    if(!vars.filterData[selectEl].hasOwnProperty(chipText)) {
      vars.filterData[selectEl][chipText] = chipID;
      $('.chip').last().clone().append($(this).children(':selected').text()).show().appendTo(els.appliedFilters).attr('data-filter',selectEl);
    }
  };

  var filterByParam = function() {
    var urlParams = new URLSearchParams(window.location.search);
    var filter = urlParams.get('clientfilter');
    
    if ( filter ) {
      var clientSelect = $('select[name="clientfilter"]');
      var styledClientSelect = clientSelect.next('div.styledSelect');
      var id = clientSelect.find('option:contains(' + filter + ')').val();

      vars.filterData['clientfilter'][filter] = id;

      styledClientSelect.text(filter).removeClass('active');
      clientSelect.val(id).trigger('change');

      $('.chip').last().clone().append(filter).show().appendTo(els.appliedFilters).attr('data-filter','clientfilter');
      
    }
  };

  var filterProjects = function() {
    var filter = $('#filter');

		$.ajax({
			url:filter.attr('action'),
			data: {
        'action' : 'projectFilters',
        'filters' : vars.filterData,
      }, 
      type:filter.attr('method'), // POST
			success:function(data){
				$('#response').html(data);
      },
      error: function(err){
        console.log(err);
        // console.log(vars.filterData);
      }
		});
    return false;
  };

  var customSelects = function() {
    els.selects.each(function() {
      var $this = $(this),
          optionCount = $(this).children('option').length;

      $this.addClass('s-hidden');
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="styledSelect"></div>');

      var $styledSelect = $this.next('div.styledSelect');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
        'class': 'options' }).insertAfter($styledSelect);

      for(var i = 1; i < optionCount; i++ ) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val() }).appendTo($list);
        }
      
      var $listItems = $list.children('li');

      $styledSelect.click(function (e) {
        e.stopPropagation();
        if( !$styledSelect.hasClass('active') ) {
          $('div.styledSelect.active').each(function () {
              $(this).removeClass('active').next('ul.options').hide();
          });
          $(this).toggleClass('active').next('ul.options').toggle();
        } else {
          $styledSelect.removeClass('active');
          $list.hide();
        }
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel')).trigger('change');
        $list.hide();
      });

      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });
    })
  };

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        selects: $('.filter'),
        appliedFilters: $('.applied-filters'),
        closeIcons: $('.close-button'),
      };

      vars = {
          filterData: {
            categoryfilter: {},
            genrefilter: {},
            clientfilter: {}
          },
        }

      listen();
      customSelects();
      filterByParam();
    }
  };
};

$(function() {
  var filters = new DS.filters();
  filters.init();
});
