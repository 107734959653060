jQuery(function($){

  $('.quote-slider').slick({
    autoplay: true,
    autoplaySpeed: 12000,
    adaptiveHeight: true,
    fade: true,
    arrows:false,
    speed: 600,
    cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  });

  $('.gallery-slider').slick({
    arrows: false,
    fade: true,
    adaptiveHeight: true,
    lazyLoad:"progressive",
    asNavFor: '.slider-nav',
    cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  });

  $('.slider-nav').slick({
    slidesToShow: 6,
    asNavFor: '.gallery-slider',
    arrows:false,
    speed: 600,
    lazyLoad:"progressive",
    focusOnSelect: true,
    infinite: false,
    cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  });

});
